import React, { useRef } from 'react';
import clx from 'classnames';
import { usePathname } from 'next/navigation';

import { NavigationType } from '../../../../components/SiteHeader/SiteHeader';
import { useMegaMenuActiveItemContext, useMegaMenuLeftPositioningContext, useMegaMenuSetActiveItemContext } from '../context/MegaMenuContext';

import NavigationLink from '../../common/NavigationLink/NavigationLink';
import Icon from '../../../../components/_ui/_blocks/Icon/Icon';

import styles from './TopLevelLink.module.css';
import { shouldChildLinksBeRendered } from '../../helpers/checkLinkBlockedByGppc';

interface Props {
    navItem: NavigationType['items'][number];
}

const TopLevelLink = ({ navItem }: Props) => {
    const activeItem = useMegaMenuActiveItemContext();
    const setActiveItem = useMegaMenuSetActiveItemContext();
    const leftPositioning = useMegaMenuLeftPositioningContext();

    const pathName = usePathname() || '';

    const listItemref = useRef<HTMLLIElement | null>(null);

    const handleSetActiveItem = () => {
        let startingPosition = 0;

        if (listItemref.current) {
            const rect = listItemref.current.getBoundingClientRect();
            startingPosition = rect.left;
        }

        leftPositioning.current[navItem._key] = startingPosition;
        setActiveItem(navItem._key);
    }

    const isLinkExpanded = activeItem === navItem._key;

    const linkClassNames = clx(
        styles.link,
        navItem?.demoted ? 'type-080' : '',
    );

    return (
        <li ref={listItemref} className={styles.listItem}>
            <NavigationLink
                navItem={navItem}
                className={linkClassNames}
                onFocus={handleSetActiveItem}
                onMouseEnter={handleSetActiveItem}
                aria-haspopup
                aria-expanded={isLinkExpanded}
                aria-controls={`mm-${navItem._key}`}
            >
                {navItem.title}
                {navItem.children?.length ? (
                    <Icon
                        icon="chevron-down-light"
                        size="xxsmall"
                        alt=""
                        aria-hidden="true"
                        loading="eager"
                        className={`pt-025 ${!shouldChildLinksBeRendered(navItem, pathName) ? 'invisible' : ''}`}
                    />
                ) : null}
            </NavigationLink>
        </li>
    );
};

export default TopLevelLink;
