import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { SidebarNavigationProvider } from './context/SidebarNavigationContext';
import useOnNavigate from '../../../hooks/useOnNavigate';

import Mask from './Mask/Mask';
import Header from './Header/Header';
import SidebarLink from './SidebarLink/SidebarLink';
import Divider from './Divider/Divider';

import styles from './SidebarNavigation.module.css';

type HeaderProps = React.ComponentProps<typeof Header>;

interface Props {
    title: HeaderProps['title'];
    onClose: HeaderProps['onClose'];
    isOpen?: boolean;
    children: React.ReactNode;
}

const SidebarNavigation = ({ title, onClose, isOpen, children }: Props) => {
    useOnNavigate(onClose);

    return (
        <SidebarNavigationProvider>
            <Transition show={isOpen} as={Dialog} onClose={onClose}>
                <Transition.Child
                    enter="transition-opacity ease-default duration-fast"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-default duration-fast"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Mask isOpen={isOpen} onClose={onClose} />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition-transform ease-default duration-fast"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-[0]"
                    leave="transition-transform ease-default duration-fast"
                    leaveFrom="translate-x-[0]"
                    leaveTo="translate-x-full"
                >
                    <nav
                        className={styles.nav}
                        aria-label={title}
                    >
                        <Header title={title} onClose={onClose} />
                        <ul className={styles.navItems}>
                            {children}
                        </ul>
                    </nav>
                </Transition.Child>
            </Transition>
        </SidebarNavigationProvider>
    );
};

SidebarNavigation.Link = SidebarLink;
SidebarNavigation.Divider = Divider;

export default SidebarNavigation;
