import React, { useRef } from 'react';
import clx from 'classnames';

import useCreateDropDowns from '../hooks/useCreateDropDowns';
import useIsOverflowActive from '../../../hooks/useIsOverflowActive';

import DropDownLink from './DropDownLink/DropDownLink';

import styles from './DropDown.module.css';

type UseCreateDropDownsReturnType = ReturnType<typeof useCreateDropDowns>;

interface Props {
    dropDown: UseCreateDropDownsReturnType['dropDowns'][number];
    stackTrace: string[];
    isActive?: boolean;
}

const DropDown = ({ dropDown, stackTrace, isActive }: Props) => {
    const listRef = useRef(null);
    const isFirstDropDown = dropDown.depth === 0;
    const isOpen = isFirstDropDown ? isActive : isActive && stackTrace.includes(dropDown.parentKey);

    const isOverflowActive = useIsOverflowActive(listRef, 'height', [
        isOpen
    ]);

    const wrapperClassName = clx(
        styles.dropDown,
        isOpen ? styles.dropDownOpen : '',
        isOverflowActive ? styles.overflowIndicator : '',
        isFirstDropDown ? styles.firstDropDown : styles.floatingDropDown,
    );

    const style = {
        '--depth': dropDown.depth.toString(),
    } as React.CSSProperties;

    return (
        <div className={wrapperClassName} style={style}>
            <ul
                className={styles.list}
                ref={listRef}
                id={`mm-${dropDown.parentKey}`}
                aria-labelledby={dropDown.title}
                aria-hidden={!isOpen}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={!isOpen ? -1 : 0}
                // @ts-expect-error
                inert={!isOpen ? 'true' : undefined}
                data-mm-depth={dropDown.depth}
            >
                {dropDown.navItems.map((DropDownNavItemNavItem) => (
                    <DropDownLink
                        key={DropDownNavItemNavItem._key}
                        navItem={DropDownNavItemNavItem}
                        stack={[...dropDown.stackTrace, DropDownNavItemNavItem._key]}
                    />
                ))}
            </ul>
        </div>
    );
};

export default DropDown;
