import React, { Fragment } from 'react';
import clx from 'classnames';
import { usePathname } from 'next/navigation';

import { MegaMenuProvider, useMegaMenuActiveItemContext, useMegaMenuNavRefContext } from './context/MegaMenuContext';
import { NavigationType } from '../../../components/SiteHeader/SiteHeader';
import useHandleMegaMenuClose from './hooks/useHandleMegaMenuClose';
import { DropDownProvider } from './DropDowns/context/DropDownContext';
import { shouldLinkBeRendered } from '../helpers/checkLinkBlockedByGppc';

import TopLevelLink from './TopLevelLink/TopLevelLink';
import DropDowns from './DropDowns/DropDowns';

import styles from './MegaMenu.module.css';

interface Props {
    ariaLabelBy: string;
    navItems: Array<NavigationType['items'][number]>;
    className?: string;
}

const allowedTopLevelNavItems = [
    'custom',
    'reference',
    'group',
];

/**
 * Root Mega Menu component.
 */
const MegaMenuContent = ({ ariaLabelBy, className, navItems }: Props) => {
    const navRef = useMegaMenuNavRefContext();
    const activeItem = useMegaMenuActiveItemContext();
    const pathName = usePathname();

    const {
        handleMouseLeave,
    } = useHandleMegaMenuClose(navRef);

    const classNames = clx(
        styles.nav,
        className,
    );

    return (
        <Fragment>
            <nav
                ref={navRef}
                className={classNames}
                aria-label={ariaLabelBy}
                onMouseLeave={handleMouseLeave}
            >
                {/* These are the top level menu items shown initially */}
                <ul className={`main-navigation ${styles.list}`}>
                    {navItems.map((navItem) => (
                        allowedTopLevelNavItems.includes(navItem.type) && shouldLinkBeRendered(navItem, pathName || '')
                            ? <TopLevelLink key={navItem._key} navItem={navItem} />
                            : null
                    ))}
                </ul>
                {/* Loop through the top level items and render out the dropdowns for each item. */}
                {navItems.map((navItem) => (
                    navItem ?.children?.length ? (
                        <DropDownProvider key={navItem._key}>
                            <DropDowns navItem={navItem} isTopLevelActive={activeItem === navItem._key} />
                        </DropDownProvider>
                    ) : null
                ))}
            </nav>
        </Fragment>
    );
};

const MegaMenu = ({ ...rest }: Props) => (
    <MegaMenuProvider>
        <MegaMenuContent {...rest} />
    </MegaMenuProvider>
);

export default MegaMenu;
