'use client';

import React, { useLayoutEffect } from 'react';
import { PortableText, type PortableTextComponents } from 'next-sanity';

import { Promotions } from '../../../lib/sanity/queries/sitewideContent.query';
import { animationDuration, stopAnimations, startAnimationSequence } from './helpers/bannerAnimation';
import useGroupedPromotions from './hooks/useGroupedPromotions';

import Link from '../../NextJs/Link/Link';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from './PromotionsBanner.module.css';

interface Props {
    promotions: Promotions;
}

const components: PortableTextComponents = {
    block: {
        normal: ({ children }) => (
            <span>{children}</span>
        ),
    },
    marks: {
        link: ({ children, value }) => {
            if (!value?.href) {
                return null;
            }

            const isRelativeUrl = value.href.startsWith('/');

            if (isRelativeUrl) {
                return <Link className="type-link" href={value.href} />;
            }

            return <a className="type-link" href={value.href} rel="noreferrer noopener">{children}</a>;
        },
    }
};

export const PromotionsBannerSsr = () => (
    <div className={`${styles.banner} h-[2.3125rem]`} data-site-banner="true" />
);

/**
 * Animated banner for the site.
 */
const PromotionsBannerContent = ({ promotions }: Props) => {
    const { groupedPromotions, shouldAnimate } = useGroupedPromotions(promotions);

    useLayoutEffect(() => {
        if (!shouldAnimate) {
            return () => {};
        }

        stopAnimations();
        startAnimationSequence();

        return () => {
            stopAnimations();
        }
    }, [groupedPromotions, shouldAnimate]);

    // Restart the animation if they have been on another tab/window and come back to this one.
    useLayoutEffect(() => {
        const handleVisibilityChange = () => {
            if (!shouldAnimate) {
                return;
            }

            if (document.visibilityState === 'visible') {
                stopAnimations();
                startAnimationSequence();
            } else {
                stopAnimations();
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            stopAnimations();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [shouldAnimate]);

    return (
        <div className={styles.banner}>
            <ul className={styles.list} data-site-banner="true">
                {groupedPromotions.map((promotions) => {
                    const key = promotions.map(({ _key }) => _key).join('');

                    return (
                        <li
                            key={key}
                            className={shouldAnimate ? 'opacity-0' : undefined}
                            style={{
                                animationDuration: `${animationDuration}ms`,
                            }}
                        >
                            {promotions.map((promotion) => (
                                <div key={promotion._key}>
                                    <Icon icon="stamp-light" size="small" alt="" aria-hidden="true" />
                                    <PortableText components={components} value={promotion.content} />
                                </div>
                            ))}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default function PromotionsBanner({ promotions }: Props) {
    return typeof window !== 'undefined' && !!document.getAnimations
        ? <PromotionsBannerContent promotions={promotions} />
        : null
};
