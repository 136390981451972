'use client';

import { createContext, useState, useContext } from 'react';

type StackTrace = Array<string>;

export interface MegaMenuState {
    stackTrace: Array<string>;
    startingPosition: number;
};

const DropDownStackTraceContext = createContext<StackTrace>([]);
const DropDownSetStackTraceContext = createContext<React.Dispatch<React.SetStateAction<StackTrace>>>(() => {});

interface Props {
    children: React.ReactNode;
}

export const DropDownProvider = ({ children }: Props) => {
    const [stackTrace, setStackTrace] = useState<StackTrace>([]);

    return (
        <DropDownStackTraceContext.Provider value={stackTrace}>
            <DropDownSetStackTraceContext.Provider value={setStackTrace}>
                {children}
            </DropDownSetStackTraceContext.Provider>
        </DropDownStackTraceContext.Provider>
    );
};

export const useDropDownStackTraceContext = () => {
    const megaMenuState = useContext(DropDownStackTraceContext);
    if (megaMenuState === undefined) {
        throw new Error('useDropDownStackTraceContext must be used within a MegaMenuProvider.');
    }

    return megaMenuState;
};

export const useDropDownSetStackTraceContext = () => {
    const modifyMegaMenuStack = useContext(DropDownSetStackTraceContext);
    if (modifyMegaMenuStack === undefined) {
        throw new Error('useDropDownSetStackTraceContext must be used within a DropDownProvider.');
    }

    return modifyMegaMenuStack;
};
