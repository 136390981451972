'use client';

import { createContext, useState, useContext, useMemo } from 'react';

type SidebarNavigationStack = Array<string>;

interface ModifySidebarNavigationStack {
    addToStack: (entry: string) => void;
    clearStack: () => void;
    popStack: () => void;
}

const SidebarNavigationStackContext = createContext<SidebarNavigationStack>([]);
const UpdateSidebarNavigationStackContext = createContext<ModifySidebarNavigationStack>({} as ModifySidebarNavigationStack);

interface Props {
    children: React.ReactNode;
}

export const SidebarNavigationProvider = ({ children }: Props) => {
    const [stackTrace, setStackTrace] = useState<SidebarNavigationStack>([]);

    const modifySidebarNavigationStack = useMemo(() => ({
        addToStack: (entry: string) => {
            setStackTrace((stack) => {
                if (stack.includes(entry)) {
                    return stack.filter((item) => item !== entry);
                }

                return [
                    ...stack,
                    entry,
                ]
            })
        },
        clearStack: () => {
            setStackTrace([]);
        },
        popStack: () => {
            setStackTrace((stack) => (
                stack.slice(0, -1)
            ));
        }
    }), []);

    return (
        <SidebarNavigationStackContext.Provider value={stackTrace}>
            <UpdateSidebarNavigationStackContext.Provider value={modifySidebarNavigationStack}>
                {children}
            </UpdateSidebarNavigationStackContext.Provider>
        </SidebarNavigationStackContext.Provider>
    );
};

export const useSidebarNavigationStackContext = () => {
    const stack = useContext(SidebarNavigationStackContext);
    if (stack === undefined) {
        throw new Error('useSidebarNavigationStackContext must be used within a SidebarNavigationProvider.');
    }

    return stack;
};

export const useSidebarNavigationModifyStackContext = () => {
    const modifySidebarNavigationStack = useContext(UpdateSidebarNavigationStackContext);
    if (modifySidebarNavigationStack === undefined) {
        throw new Error('useSidebarNavigationModifyStackContext must be used within a SidebarNavigationProvider.');
    }

    return modifySidebarNavigationStack;
};
