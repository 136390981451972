'use client';

import React, { Fragment } from 'react';

import { HeaderFields } from '../../../components/SiteHeader/SiteHeader';
import { useIsMainMenuOpenContext, useSetMainMenuOpenContext } from '../../../data/context/SiteNavigationContext';
import { useAuthContext } from '../../../data/context/authContext';

import MegaMenu from '../MegaMenu/MegaMenu';
import SidebarNavigation from '../SidebarNavigation/SidebarNavigation';

interface Props {
    mainNavigation: HeaderFields['mainNavigation'];
};

const signInLink = {
    _key: 'main-menu-icon',
    _type: '',
    title: 'Sign in',
    type: 'custom' as const,
    link: '/login',
    icon: 'profile-light',
    enabled: true,
}

const MainNavigation = ({ mainNavigation }: Props) => {
    const { isLoggedIn } = useAuthContext();

    const isMainMenuOpen = useIsMainMenuOpenContext();
    const setMainMenuOpen = useSetMainMenuOpenContext();

    const handleClose = () => {
        setMainMenuOpen(false);
    }

    return (
        <Fragment>
            <MegaMenu ariaLabelBy="Main menu" navItems={mainNavigation?.items || []} className="hidden lg:block" />
            <SidebarNavigation title="Menu" isOpen={isMainMenuOpen} onClose={handleClose}>
                {(mainNavigation?.items || []).map((navItem) => (
                    <SidebarNavigation.Link key={navItem._key} navItem={navItem} />
                ))}
                {!isLoggedIn ? (
                    <Fragment>
                        <SidebarNavigation.Divider />
                        <SidebarNavigation.Link navItem={signInLink} />
                    </Fragment>
                ) : null}
            </SidebarNavigation>
        </Fragment>
    );
};

export default MainNavigation;
