'use client';

import { createContext, useState, useContext, useRef } from 'react';

interface LeftPositioning {
    [key: string]: number;
}

const MegaMenuNavContext = createContext<React.MutableRefObject<HTMLElement | null>>(null as unknown as React.MutableRefObject<null>);
const MegaMenuLeftPositioningContext = createContext<React.MutableRefObject<LeftPositioning>>({} as unknown as React.MutableRefObject<LeftPositioning>);
const MegaMenuActiveItemContext = createContext<string>('');
const MegaMenuSetActiveItemContext = createContext<React.Dispatch<React.SetStateAction<string>>>((() => {}) as unknown as React.Dispatch<React.SetStateAction<string>>);

interface Props {
    children: React.ReactNode;
}

export const MegaMenuProvider = ({ children }: Props) => {
    const navRef = useRef<HTMLElement | null>(null);
    const leftPositioning = useRef<LeftPositioning>({});
    const [activeItem, setActiveItem] = useState<string>('');

    return (
        <MegaMenuNavContext.Provider value={navRef}>
            <MegaMenuLeftPositioningContext.Provider value={leftPositioning}>
                <MegaMenuActiveItemContext.Provider value={activeItem}>
                    <MegaMenuSetActiveItemContext.Provider value={setActiveItem}>
                        {children}
                    </MegaMenuSetActiveItemContext.Provider>
                </MegaMenuActiveItemContext.Provider>
            </MegaMenuLeftPositioningContext.Provider>
        </MegaMenuNavContext.Provider>
    );
};

export const useMegaMenuNavRefContext = () => {
    const navRef = useContext(MegaMenuNavContext);
    if (navRef === undefined) {
        throw new Error('useMegaMenuNavRefContext must be used within a MegaMenuProvider.');
    }

    return navRef;
};

export const useMegaMenuLeftPositioningContext = () => {
    const leftPositioning = useContext(MegaMenuLeftPositioningContext);
    if (leftPositioning === undefined) {
        throw new Error('useMegaMenuLeftPositioningContext must be used within a MegaMenuProvider.');
    }

    return leftPositioning;
};

export const useMegaMenuActiveItemContext = () => {
    const activeItem = useContext(MegaMenuActiveItemContext);
    if (activeItem === undefined) {
        throw new Error('useMegaMenuActiveItemContext must be used within a MegaMenuProvider.');
    }

    return activeItem;
};

export const useMegaMenuSetActiveItemContext = () => {
    const setActiveItem = useContext(MegaMenuSetActiveItemContext);
    if (setActiveItem === undefined) {
        throw new Error('useSetMegaMenuActiveItemContext must be used within a MegaMenuProvider.');
    }

    return setActiveItem;
};
