import React from 'react';
import clx from 'classnames';

import { PortableText } from 'next-sanity';
import { NavigationItemFields } from '../../../../lib/sanity/queries/navigationItem.query';

import Typography from '../../../../components/_ui/_blocks/Typography/Typography';
import NavigationLink from '../NavigationLink/NavigationLink';
import Icon from '../../../../components/_ui/_blocks/Icon/Icon';

import styles from './DiscountLink.module.css';
import { textHighlightedComponents } from '../../../../components/_ui/_blocks/Typography/utils/HighlightText';

interface Props  {
    navItem: NavigationItemFields;
    className?: string;
}

const DiscountLink = ({ navItem, className }: Props) => {
    if (navItem.type !== 'discountLink') {
        return null;
    }

    const classNames = clx(
        navItem.discountSize !== 'large' ? styles.listItemDefault : styles.listItemLarge,
        className,
    );

    return (
        <Typography
            as="li"
            typeset="heading"
            size="080"
            className={classNames}
        >
            <NavigationLink navItem={navItem} className={navItem.discountSize !== 'large' ? styles.linkDefault : styles.linkLarge}>
                <span>
                    {navItem.discountLinkText && (
                        <PortableText 
                            components={textHighlightedComponents()} 
                            value={navItem.discountLinkText} 
                        />
                    )}
                </span>
                <Icon
                    icon="chevron-right-dark"
                    size="small"
                    alt=""
                    aria-hidden="true"
                    loading="eager"
                    className="ml-auto"
                />
            </NavigationLink>
        </Typography>
    );
};

export default DiscountLink;
