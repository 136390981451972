'use client';

import React, { Fragment } from 'react';

import { useAuthContext } from '../../../data/context/authContext';
import { useSetAccountMenuOpenContext, useIsAccountMenuOpenContext } from '../../../data/context/SiteNavigationContext';
import { NavigationItemFields } from '../../../lib/sanity/queries/navigationItem.query';

import useIsClient from '../../../hooks/useIsClient';
import usePatientReorders from './hooks/usePatientReorders';

import SidebarNavigation from '../SidebarNavigation/SidebarNavigation';
import Reorder from './Reorders/Reorders';
import Icon from '../../../components/_ui/_blocks/Icon/Icon';

import styles from './AccountNavigation.module.css';

interface ManualNavItems {
    [key: string]: NavigationItemFields;
}

const manualNavItems: ManualNavItems = {
    dashboard: {
        _key: 'account-dashboard',
        _type: 'navigationItem',
        link: '/account',
        title: 'Account dashboard',
        type: 'custom',
        enabled: true,
    },
    orderHistory: {
        _key: 'order-history',
        _type: 'navigationItem',
        link: '/account/orders',
        title: 'Order history',
        type: 'custom',
        enabled: true,
    },
    reorders: {
        _key: 'treatment-reorders',
        _type: 'navigationItem',
        link: '',
        title: 'Treatment reorders',
        type: 'group',
        enabled: true,
        classes: 'reorders',
    },
    BillingAndDelivery: {
        _key: 'billing-delivery',
        _type: 'navigationItem',
        link: '/account/delivery-addresses',
        title: 'Billing & delivery',
        type: 'custom',
        enabled: true,
    },
    medicalRecords: {
        _key: 'medical-records',
        _type: 'navigationItem',
        link: '/account/personal-medical-record',
        title: 'Medical records',
        type: 'custom',
        enabled: true,
    },
    manageAccount: {
        _key: 'manage-account',
        _type: 'navigationItem',
        link: '/account/settings',
        title: 'Manage account',
        type: 'custom',
        enabled: true,
    }
}

const AccountNavigation = () => {
    const { isLoggedIn, patientName, logout } = useAuthContext();
    const isClient = useIsClient();

    const setNavigationOpen = useSetAccountMenuOpenContext();
    const isNavigationOpen = useIsAccountMenuOpenContext();

    const handleClose = () => {
        setNavigationOpen(false);
    }

    const handleLogout = () => {
        logout();
    }

    const { reorders, isLoading } = usePatientReorders();

    return (
        <SidebarNavigation
            title={isClient && isLoggedIn ? `Hello, ${patientName || 'user'}` : 'Sign In'}
            // label="Account menu"
            isOpen={isNavigationOpen}
            onClose={handleClose}
        >
            <SidebarNavigation.Link navItem={manualNavItems.dashboard} />
            <SidebarNavigation.Link navItem={manualNavItems.orderHistory} />
            {reorders.length ? (
                <Fragment>
                    <SidebarNavigation.Divider />
                    <SidebarNavigation.Link navItem={manualNavItems.reorders} forceSubPanelIndicator />
                    <Reorder reorders={reorders} isLoading={isLoading} />
                    <SidebarNavigation.Divider />
                </Fragment>
            ) : null}
            <SidebarNavigation.Link navItem={manualNavItems.BillingAndDelivery} />
            <SidebarNavigation.Link navItem={manualNavItems.medicalRecords} />
            <SidebarNavigation.Link navItem={manualNavItems.manageAccount} />
            {isLoggedIn ? (
                <Fragment>
                    <SidebarNavigation.Divider />
                    <li className="py-025 px-050">
                        <button type="button" className={styles.logOut} onClick={handleLogout}>
                            <Icon icon="exit-light" alt="" aria-hidden />
                            Sign out
                        </button>
                    </li>
                </Fragment>
            ) : null}
        </SidebarNavigation>
    );
};

export default AccountNavigation;
