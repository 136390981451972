'use client';

import React, { Fragment, useRef, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import useScrollDirection from '../../../hooks/useScrollDirection';
import useScrollPosition from '../../../hooks/useScrollPosition';
import useWindowSizeCheck from '../../../hooks/useWindowSizeCheck';

import Container from '../../_ui/_blocks/Container/Container';
import Link from '../../NextJs/Link/Link';
import SearchInput from '../../Search/SearchInput/SearchInput';
import SecureCheckoutNotice from './SecureCheckoutNotice/SecureCheckoutNotice';
import { AccountButtonLoadingUi } from './AccountButton/AccountButton';
import BasketButton from './BasketButton/BasketButton';
import MainMenuButton from './MainMenuButton/MainMenuButton';

import styles from './StickyHeader.module.css';

const AccountButton = dynamic(() => import('./AccountButton/AccountButton'), {
    ssr: false,
    loading: () => <AccountButtonLoadingUi />
});

interface Props {
    disableNavigation?: boolean;
    disableSearch?: boolean;
    disableAccount?: boolean;
    disableBasket?: boolean;
    enableSecureCheckout?: boolean;
    disableStickyHeader?: boolean;
}

interface StickyHeaderWrapperProps {
    scrollPosition: number;
    stickyDisabled?: boolean;
    height: 'full' | 'slim';
    children: React.ReactNode;
}

const StickyHeaderWrapper = ({ scrollPosition, height, stickyDisabled, children }: StickyHeaderWrapperProps) => {
    const lastStickyValue = useRef(false);

    const scrollDirection = useScrollDirection({
        disabled: stickyDisabled,
    });

    // If the header is sticky or not.
    const isSticky = useMemo(() => {
        if (scrollDirection === 'up' || scrollPosition < 136) {
            lastStickyValue.current = true;
            return true;
        }

        if (scrollDirection === 'down') {
            lastStickyValue.current = false;
            return false;
        }

        return lastStickyValue.current;
    }, [scrollDirection, scrollPosition]);

    if (stickyDisabled) {
        return children;
    }

    return (
        <Fragment>
            <Transition
                as={Fragment}
                show={isSticky}
                enter="!duration-fast !ease-out"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="!duration-fast !ease-out"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
                unmount={false}
            >
                {children}
            </Transition>
            <div className={`${styles.stickyHeaderPlaceholder} ${height === 'full' ? 'h-[8.5rem]' : ''}`} />
        </Fragment>
    )
}

const StickyHeader = ({
    disableStickyHeader, disableNavigation, disableAccount, disableBasket, disableSearch, enableSecureCheckout
}: Props) => {
    const shouldRenderLinks = !disableNavigation || !disableAccount || !disableBasket || !disableSearch || enableSecureCheckout;

    const [isDesktop] = useWindowSizeCheck(1023);

    const stickyDisabled = isDesktop || disableStickyHeader;

    const scrollPosition = useScrollPosition({
        disabled: stickyDisabled,
    });

    // Adds class to shrink the header size.
    const headerScrolled = scrollPosition >= 136 ? styles.headerScrolled : '';

    return (
        <StickyHeaderWrapper
            height={disableSearch ? 'slim' : 'full'}
            scrollPosition={scrollPosition}
            stickyDisabled={stickyDisabled}
        >
            {/* Data attribute used in global.css. */}
            <header
                className={`${styles.header} ${headerScrolled} ${!disableStickyHeader ? styles.headerSticky : ''}`}
                data-site-header="true"
            >
                <Container as="div" size="large" className={styles.container}>
                    <Link
                        href="/"
                        title="The Independent Pharmacy homepage"
                        aria-label="The Independent Pharmacy homepage"
                        className={styles.logo}
                    >
                        <Image
                            src="/images/the-independent-pharmacy-logo.svg"
                            alt="The Independent Pharmacy"
                            width={124}
                            height={42}
                            loading="eager"
                        />
                    </Link>
                    {!disableSearch ? (
                        <div className={styles.search}>
                            <SearchInput name="header-search" placeholder="Search..." invokesOverlay />
                        </div>
                    ) : null}
                    {shouldRenderLinks ? (
                        <div className={styles.links}>
                            {enableSecureCheckout ? <SecureCheckoutNotice /> : null}
                            {!disableAccount ? <AccountButton /> : null}
                            {!disableBasket ? <BasketButton /> : null}
                            {!disableNavigation ? <MainMenuButton /> : null}
                        </div>
                    ) : null}
                </Container>
            </header>
        </StickyHeaderWrapper>
    );
};

export default StickyHeader;
