import { useEffect, useRef } from 'react';

const useIsOverflowActive = (elementRef: React.MutableRefObject<HTMLUListElement | null>, direction: 'height' | 'width', deps: any[]) => {
    const isOverflowActive = useRef(false);

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        if (direction === 'width') {
            isOverflowActive.current = elementRef.current.scrollWidth > elementRef.current.clientWidth;
            return;
        }

        isOverflowActive.current = elementRef.current.scrollHeight > elementRef.current.clientHeight;
    }, [...deps, elementRef, direction]);

    return isOverflowActive.current;
};

export default useIsOverflowActive;
