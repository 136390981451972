import { SITE_URL } from '../../../config/config';
import { NavigationItemWFieldsWithChildren } from '../../../lib/sanity/queries/navigationItem.query';
import { deriveURLFromNavigationItem } from '../common/NavigationLink/NavigationLink';

/**
 * Works out if a custom menu should be shown.
 */
export const shouldLinkBeRendered = (navItem: NavigationItemWFieldsWithChildren, currentPathName: string) => {
    const url = deriveURLFromNavigationItem(navItem);

    if (!url) {
        return true;
    }

    // If the nav link has a reference to a brand page.
    let isBrandPage = navItem.reference?._type === 'brand-pages';

    // (f there is no brand page, check if the url is to a brand page.)
    if (!isBrandPage) {
        const urlObject = new URL(url, SITE_URL);
        const urlPathName = urlObject.pathname;

        const splitPathName = urlPathName.split('/').filter((item) => !!item);

        // If the url has more than one segment and the first segment is "brands".
        isBrandPage = splitPathName.length > 1 && splitPathName[0] === 'brands';
    }

    // If it's a brand item and we're on a GPPC page.
    if (isBrandPage && currentPathName.includes('/gppc')) {
        return false;
    }

    // If it's a treatment and we're on the homepage or GPPC page.
    if (navItem.reference?._type === 'treatment' && (currentPathName === '/' || currentPathName.includes('/gppc') || currentPathName.includes('/ad/'))) {
        return false;
    }

    return true;
}

/**
 * Works out if a custom menu should be shown.
 */
export const shouldChildLinksBeRendered = (navItem: NavigationItemWFieldsWithChildren, currentPathName: string) => {
    const url = deriveURLFromNavigationItem(navItem);

    if (!url || !navItem.children?.length) {
        return true;
    }

    const urlObject = new URL(url, SITE_URL);
    const urlPathName = urlObject.pathname;

    // If it's the brands menu item and we're on a GPPC page.
    if (urlPathName === '/brands' && currentPathName.includes('/gppc')) {
        return false;
    }

    // If it's the treatments nav item and we're on the homepage or GPPC page.
    if (urlPathName === '/treatments' && (currentPathName === '/' || currentPathName.includes('/gppc') || currentPathName.includes('/ad/'))) {
        return false;
    }

    return true;
}
