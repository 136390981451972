import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { NavigationType } from '../../../../components/SiteHeader/SiteHeader';
import { useMegaMenuLeftPositioningContext } from '../context/MegaMenuContext';
import { useDropDownStackTraceContext, useDropDownSetStackTraceContext } from './context/DropDownContext';
import useCreateDropDowns from './hooks/useCreateDropDowns';

import DropDownBackground from './DropDownBackground/DropDownBackground';
import DropDown from './DropDown/DropDown';

import styles from './DropDowns.module.css';

interface Props {
    navItem: NavigationType['items'][number];
    isTopLevelActive: boolean;
}

const DropDowns = ({ navItem, isTopLevelActive }: Props) => {
    const dropDownsRef = useRef<HTMLDivElement | null>(null);
    const stackTrace = useDropDownStackTraceContext();
    const setStackTrace = useDropDownSetStackTraceContext();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isTopLevelActive && !isActive) {
            setIsActive(true);
        }
    }, [isActive, isTopLevelActive]);

    const handleExited = () => {
        setIsActive(false);
        setStackTrace([]);

        // Scrolls all the lists back to the top on close.
        if (dropDownsRef.current) {
            const lists = dropDownsRef.current.querySelectorAll('ul');
            lists.forEach((list) => {
                // eslint-disable-next-line no-param-reassign
                list.scrollTop = 0;
            });
        }
    }

    const leftPositioning = useMegaMenuLeftPositioningContext();
    const positioningStyle = {
        '--starting-position': `${leftPositioning.current[navItem._key] || 0}px`,
    } as React.CSSProperties;

    const { dropDowns, largestDepth } = useCreateDropDowns(navItem);

    return (
        <CSSTransition
            in={isTopLevelActive}
            timeout={400}
            onExited={handleExited}
            classNames={{
                enter: styles.dropDownsOpen,
                enterActive: styles.dropDownsOpen,
                enterDone: styles.dropDownsOpen,
            }}
        >
            <div
                ref={dropDownsRef}
                style={positioningStyle}
                className={styles.dropDowns}
            >
                {dropDowns.map((dropDown) => (
                    <DropDown
                        key={dropDown.parentKey}
                        dropDown={dropDown}
                        stackTrace={stackTrace}
                        isActive={isActive}
                    />
                ))}
                {Array.from({ length: largestDepth }, (_, i) => i + 1).map((value) => (
                    <DropDownBackground key={value} depth={value} stackTrace={stackTrace} />
                ))}
            </div>
        </CSSTransition>
    )
};

export default DropDowns;
