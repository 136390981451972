import { useRouter } from 'next/navigation';

import { sessionStorageManager } from '../../../helpers/sessionStorageManager';
import { ConsultationMeta } from '../../../app/(consultation)/[dynamicRoute]/consultation/_components/Context/types/consultationDataTypes';

/**
 * Gets any search params form the URL and formats it to a string.
 * If there are values, it adds the question mark as well.
 * If there are no values, returns an empty string.
 */
const getSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search).toString();
    return searchParams ? `?${searchParams}` : '';
}

export const getConsultationSessionData = (conditionId: number): ConsultationMeta | null => {
    const consultationData = sessionStorageManager.getItem<string>(`consultation-${conditionId}`);
    return consultationData ? JSON.parse(consultationData) : null;
}

export const setConsultationSessionData = (newConsultationMeta: ConsultationMeta) => {
    sessionStorageManager.setItem(
        `consultation-${newConsultationMeta.conditionId}`,
        JSON.stringify(newConsultationMeta)
    );
}

/**
 * A hook that returns a function which starts a consultation.
 */
const useStartConsultation = () => {
    const router = useRouter();

    return (conditionSlug: string, newConsultationMeta: ConsultationMeta) => {
        // There may be rare cases where condition slug was not available or was passed incorrectly. If that
        // is the case, we want to stop the consultation process and throw an error for sentry to pick up.
        if (!conditionSlug) {
            throw new Error(`Condition slug is not available for condition ID ${newConsultationMeta.conditionId}`);
        }

        if (!newConsultationMeta.conditionId) {
            throw new Error(`Condition ID is not available for condition ${conditionSlug}`);
        }

        setConsultationSessionData(newConsultationMeta);
        router.push(`/${conditionSlug}/consultation${getSearchParams()}`);
    };
};

export default useStartConsultation;
