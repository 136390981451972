import { useCallback, useEffect, useRef } from 'react';

import { useMegaMenuNavRefContext, useMegaMenuSetActiveItemContext } from '../context/MegaMenuContext';
import useOnNavigate from '../../../../hooks/useOnNavigate';

type UseMegaMenuRootNavContextReturnType = ReturnType<typeof useMegaMenuNavRefContext>;

interface MousePosition {
    x: number;
    y: number;
}

const getMousePosition = () => (
    new Promise<MousePosition>((resolve) => {
        const handleMouseMove = (e: MouseEvent) => {
            document.removeEventListener('mousemove', handleMouseMove);
            resolve({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);
    })
);

const useHandleMegaMenuClose = (navRef: UseMegaMenuRootNavContextReturnType) => {
    const timer = useRef<NodeJS.Timeout | undefined>();
    const setActiveItem = useMegaMenuSetActiveItemContext();

    const handleClose = useCallback(() => {
        setActiveItem('');
    }, [setActiveItem]);

    useOnNavigate(handleClose);

    const handleMouseLeave = () => {
        clearTimeout(timer.current);

        timer.current = setTimeout(async () => {
            const { x, y } = await getMousePosition();
            const element = document.elementFromPoint(x, y);

            if (navRef.current && element && !navRef.current.contains(element)) {
                handleClose();
            }
        }, 150);
    }

    useEffect(() => (
        () => {
            clearTimeout(timer.current);
        }
    ), []);

    return {
        handleMouseLeave,
    }
};

export default useHandleMegaMenuClose;
