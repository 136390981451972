import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useSidebarNavigationModifyStackContext, useSidebarNavigationStackContext } from '../context/SidebarNavigationContext';

import Typography from '../../../../components/_ui/_blocks/Typography/Typography';
import Icon from '../../../../components/_ui/_blocks/Icon/Icon';

import styles from './Header.module.css';

interface Props {
    title: string;
    closeIcon?: 'cross-light' | 'chevron-down-light';
    onClose: () => void;
}

const Header = ({ title, closeIcon = 'cross-light', onClose }: Props) => {
    const { popStack } = useSidebarNavigationModifyStackContext();
    const drillDownStack = useSidebarNavigationStackContext();

    const currentTitle = drillDownStack.at(-1) || title;

    return (
        <Typography
            as="div"
            typeset="subtitle"
            className={styles.header}
            size="200"
            lineHeight="200"
            aria-hidden="true"
            data-drill-down-header
        >
            <button
                type="button"
                disabled={!drillDownStack.length}
                onClick={popStack}
                className={`${styles.button} ${drillDownStack.length ? styles.backButtonAnimateIn : ''}`}
            >
                <Icon
                    icon="chevron-left-light"
                    size="small"
                    alt=""
                />
                <TransitionGroup component="span" className={styles.title}>
                    <CSSTransition
                        key={currentTitle}
                        timeout={500}
                        classNames={{
                            enter: styles.enter,
                            enterActive: styles.enterActive,
                            exit: styles.exit,
                            exitActive: styles.exitActive,
                        }}
                        appear
                        unmountOnExit
                    >
                        <span>{currentTitle}</span>
                    </CSSTransition>
                </TransitionGroup>
            </button>
            <button
                type="button"
                className="p-050 flex-shrink-0"
                onClick={onClose}
                title="Close menu"
            >
                <Icon
                    className="box-content"
                    icon={closeIcon}
                    size="small"
                    alt=""
                />
            </button>
        </Typography>
    );
};

export default Header;
