import React from 'react';

import Icon from '../../../_ui/_blocks/Icon/Icon';

import styles from '../StickyHeader.module.css';

const SecureCheckoutNotice = () => (
    <div className={styles.link}>
        Secure checkout
        <Icon icon="lock-dark" size="medium" alt="" aria-hidden="true" />
    </div>
);

export default SecureCheckoutNotice;
