'use client';

import React from 'react';

import { NavigationItemFields } from '../../../../lib/sanity/queries/navigationItem.query';
import useCloseMegaMenuOnClick from './hooks/useCloseMegaMenuOnClick/useCloseMegaMenuOnClick';

import LinkActiveClassName from '../../../../components/LinkActiveClassName';

type LinkActiveClassNameProps = React.ComponentProps<typeof LinkActiveClassName>;

interface Props extends Omit<LinkActiveClassNameProps, 'onClick' | 'href'> {
    navItem: NavigationItemFields;
    onClick?: () => void;
}

/**
 * Takes a sanity navigation item and returns a URL for it.
 */
export const deriveURLFromNavigationItem = (navItem: Props['navItem']) => {
    // If it's a custom link, we can just return the link.
    if (navItem.type === 'custom' || navItem.type === 'discountLink') {
        return navItem.link;
    }

    // If the type is a reference, we need to generate the URL using the reference data.
    if (navItem.type === 'reference') {
        switch (navItem.reference!._type) {
            case 'treatment':
                return `/${navItem.reference!.primaryCondition.slug.current}/${navItem.reference!.slug.current}`;

            case 'brand-pages':
                return `/brands/${navItem.reference!.slug.current}`;

            case 'meetTheTeamPage':
                return `/about/meet-the-team`;

            default:
                return `/${navItem.reference!.slug.current}`;
        }
    }

    // This should never be hit but have return here in case any new types are added so that the code
    // continues to function.
    return '';
}

const getActiveKey = (classes: NavigationItemFields['classes']) => {
    const foundActiveKey = classes
        ? classes.split(' ').find((className) => className.includes('active-key-'))
        : undefined;

    if (!foundActiveKey) {
        return undefined;
    }

    return foundActiveKey.replace('active-key-', '') || undefined;
}

/**
 * Component to render one of our sanity links.
 */
const NavigationLink = ({ navItem, children, onClick, ...rest }: Props) => {
    const closeMegaMenu = useCloseMegaMenuOnClick(onClick);

    if (!navItem.type || navItem.type === 'discountCode') {
        return null;
    }

    const activeKey = getActiveKey(navItem.classes);

    // If the type is a group of links, then there is no actual link to render.
    // TODO: group is legacy and can be removed after a while.
    if (navItem.type === 'group' || navItem.type === 'textOnly') {
        const spanProps = { ...rest };
        delete spanProps.partiallyActive;
        delete spanProps.matchesPath;
        delete spanProps.activeClassName;

        return <span {...spanProps} data-active-key={activeKey}>{children}</span>;
    }

    const href = deriveURLFromNavigationItem(navItem);

    return (
        <LinkActiveClassName {...rest} activeKey={activeKey} href={href || ''} onClick={closeMegaMenu}>
            {children}
        </LinkActiveClassName>
    );
};

export default NavigationLink;
