import React, { Fragment, useRef } from 'react';
import clx from 'classnames';
import { usePathname } from 'next/navigation';

import { useSidebarNavigationStackContext, useSidebarNavigationModifyStackContext } from '../context/SidebarNavigationContext';
import { NavigationType } from '../../../../components/SiteHeader/SiteHeader';
import { shouldLinkBeRendered, shouldChildLinksBeRendered } from '../../helpers/checkLinkBlockedByGppc';
import useIsOverflowActive from '../../hooks/useIsOverflowActive';

import Icon from '../../../../components/_ui/_blocks/Icon/Icon';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import DiscountCode from '../../common/DiscountCode/DiscountCode';
import DiscountLink from '../../common/DiscountLink/DiscountLink';

import styles from './SidebarLink.module.css';

interface Props {
    navItem: NavigationType['items'][number];
    depth?: number;
    forceSubPanelIndicator?: boolean;
}

const SidebarLink = ({ navItem, depth, forceSubPanelIndicator }: Props) => {
    const subListRef = useRef<HTMLUListElement | null>(null)
    const { addToStack } = useSidebarNavigationModifyStackContext();
    const drillDownStack = useSidebarNavigationStackContext();
    const pathName = usePathname() || '';

    const currentDepth = typeof depth === 'number' ? (depth + 1) : 0;

    const handleDrillDownClick = () => {
        addToStack(navItem.title);
    }

    const isRoot = currentDepth === 0;

    const isSubNavOpen = drillDownStack.includes(navItem.title);

    const isOverflowActive = useIsOverflowActive(subListRef, 'height', [
        isSubNavOpen
    ]);

    // If it's the last ite that was selected or is the first panel with no items selected.
    const isActiveSubPanel = drillDownStack.at(-1) === navItem.title || (isRoot && drillDownStack.length === 0);

    const isDisabled = isRoot ? !!drillDownStack.length : currentDepth < drillDownStack.length;

    const isInert = !drillDownStack.includes(navItem.title);

    const shouldRenderChildren = shouldChildLinksBeRendered(navItem, pathName);

    const linkClassNames = clx(
        styles.link,
        navItem.classes?.includes('demoted') ? 'type-080' : '',
    );

    const listClassNames = clx(
        styles.subNav,
        isSubNavOpen ? styles.subNavOpen : '',
        isOverflowActive ? styles.overflowIndicator : '',
    );

    if (!shouldLinkBeRendered(navItem, pathName)) {
        return null;
    }

    if (navItem.type === 'discountCode') {
        return <DiscountCode navItem={navItem} className={styles.discount} />;
    }

    if (navItem.type === 'discountLink') {
        return <DiscountLink navItem={navItem} className={styles.discount} />;
    }

    return (
        <li className={styles.listItem}>
            {navItem.children?.length && shouldRenderChildren ? (
                <Fragment>
                    <button
                        type="button"
                        aria-haspopup
                        aria-expanded={isActiveSubPanel}
                        onClick={handleDrillDownClick}
                        className={linkClassNames}
                        disabled={isDisabled}
                    >
                        {navItem.icon ? (
                            <Icon
                                icon={navItem.icon as React.ComponentProps<typeof Icon>['icon']}
                                size="small"
                                alt=""
                                aria-hidden="true"
                                loading="eager"
                            />
                        ) : null}
                        {navItem.title}
                        <Icon
                            icon="chevron-right-light"
                            size="small"
                            alt=""
                            aria-hidden="true"
                            loading="eager"
                            className="ml-auto"
                        />
                    </button>
                    <div className={listClassNames}>
                        <ul
                            ref={subListRef}
                            className={`h-full ${isActiveSubPanel ? 'overflow-y-auto overflow-x-hidden' : 'overflow-hidden'}`}
                            aria-label={navItem.title}
                            // Only a string of true seems to work, boolean true doesn't.
                            // @ts-expect-error
                            inert={isInert ? 'true' : undefined}
                        >
                            {navItem.children.map((navItem) => (
                                <SidebarLink depth={currentDepth} key={navItem._key} navItem={navItem} />
                            ))}
                        </ul>
                    </div>
                </Fragment>
            ) : (
                <NavigationLink
                    navItem={navItem}
                    className={linkClassNames}
                    tabIndex={isDisabled ? -1 : undefined}
                    activeClassName="font-600"
                >
                    {navItem.icon ? (
                        <Icon
                            icon={navItem.icon as React.ComponentProps<typeof Icon>['icon']}
                            size="small"
                            alt=""
                            aria-hidden="true"
                            loading="eager"
                        />
                    ) : null}
                    {navItem.title}
                    {forceSubPanelIndicator || navItem.children?.length && shouldRenderChildren ? (
                        <Icon
                            icon="chevron-right-light"
                            size="small"
                            alt=""
                            aria-hidden="true"
                            loading="eager"
                            className="ml-auto"
                        />
                    ) : null}
                </NavigationLink>
            )}
        </li>
    );
};

export default SidebarLink;
