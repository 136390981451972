import React from 'react';
import { Transition } from '@headlessui/react';

import styles from './Mask.module.css';

interface Props {
    isOpen?: boolean;
    onClose: () => void;
}

/**
 * Drill-down mask, a button that sits behind the drill-down menu, when clicked it closes the menu.
 */
const DrillDownMask = ({ isOpen, onClose }: Props) => (
    <Transition
        appear
        as="button"
        show={isOpen}
        type="button"
        disabled={!isOpen}
        tabIndex={-1}
        onClick={onClose}
        aria-hidden="true"
        className={styles.mask}
        enterFrom="!opacity-0"
        enterTo="opacity-75"
        leaveFrom="opacity-75"
        leaveTo="!opacity-0"
    />
);

export default DrillDownMask;
