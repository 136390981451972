import React from 'react';
import clx from 'classnames';

import styles from './DropDownBackground.module.css';

interface Props {
    depth: number;
    stackTrace: string[];
}

const DropDownBackground = ({ depth, stackTrace }: Props) => {
    const className = clx (
        styles.background,
        depth < stackTrace.length ? styles.backgroundOpen : '',
    );

    const style = {
        '--depth': depth.toString(),
    } as React.CSSProperties;

    return <div className={className} style={style} />;
};

export default DropDownBackground;
