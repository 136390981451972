import { useMemo } from 'react';

import { Promotions } from '../../../../lib/sanity/queries/sitewideContent.query';
import useGetWindowSize from '../../../../hooks/useGetWindowSize';

const tabletScreenSize = 768;
const desktopScreenSize = 1152;

/**
 * Works out how many items should be grouped together based on window screen size.
 */
const getSpliceNumber = (windowSize: number) => {
    if (windowSize < tabletScreenSize) {
        return 1;
    }

    if (windowSize < desktopScreenSize) {
        return 2
    }

    return 4
}
/**
 * Works out how many promotions should be in a group to be displayed at one time.
 */
const useGroupedPromotions = (promotions: Promotions) => {
    const windowSize = useGetWindowSize();

    const spliceNumber = getSpliceNumber(windowSize)

    // Groups the promotions.
    const groupedPromotions = useMemo(() => {
        if (!promotions.length) {
            return [];
        }

        const promotionsClone = [...promotions];
        const groups: Promotions[] = [];

        while (promotionsClone.length > 0) {
            groups.push(promotionsClone.splice(0, spliceNumber));
        }

        return groups;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotions.length, spliceNumber]);

    // Works out if the animation should run based on the number of promotions to display and which screen is
    // being viewed.
    const shouldAnimate = useMemo(() => {
        if (windowSize < tabletScreenSize && promotions.length > 1) {
            return true;
        }

        if (windowSize < desktopScreenSize && promotions.length > 2) {
            return true;
        }

        return promotions.length > 4;
    }, [promotions.length, windowSize]);

    return {
        groupedPromotions,
        shouldAnimate,
    }
};

export default useGroupedPromotions;
