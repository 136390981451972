import React from 'react';
import clx from 'classnames';

import { NavigationItemFields } from '../../../../lib/sanity/queries/navigationItem.query';

import Tag from '../../../../components/_ui/_blocks/Tag/Tag';
import Typography from '../../../../components/_ui/_blocks/Typography/Typography';

import styles from './DiscountCode.module.css';

interface Props  {
    navItem: NavigationItemFields;
    className?: string;
}

const DiscountCode = ({ navItem, className }: Props) => {
    if (navItem.type !== 'discountCode' || !navItem.discountCode) {
        return null;
    }

    const prefix = navItem?.discountCodePrefix || 'Use code';

    const classNames = clx(
        styles.listItem,
        className,
    );

    return (
        <Typography
            as="li"
            typeset="emphasis"
            size="080"
            className={classNames}
        >
            <span className={navItem.discountSize !== 'large' ? styles.discountDefault : styles.discountLarge}>
                {navItem.discountSize === 'default' ? (
                    <span>
                        {prefix}{' '}
                        <Tag color="discountCode">{navItem.discountCode}</Tag>{' '}
                        {navItem?.discountCodeSuffix || null}
                    </span>
                ) : (
                    <div className="flex gap-050">
                        <div>
                            {prefix}{' '}<br />
                            {navItem?.discountCodeSuffix || null}
                        </div>
                        <Tag color="discountCode">{navItem.discountCode}</Tag>{' '}
                    </div>
                )}                
            </span>
        </Typography>
    );
};

export default DiscountCode;
