import { useMemo, useRef } from 'react';
import { usePathname } from 'next/navigation';

import { NavigationType } from '../../../../../components/SiteHeader/SiteHeader';
import { shouldLinkBeRendered, shouldChildLinksBeRendered } from '../../../helpers/checkLinkBlockedByGppc';

type NavItem = NavigationType['items'][number];

interface Panel {
    title: string;
    parentKey: NavItem['_key'],
    stackTrace: string[];
    depth: number;
    navItems: NonNullable<NavItem['children']>;
}

const useCreateDropDowns = (navItem: NavItem) => {
    const largestDepth = useRef(0);

    const pathName = usePathname();

    const dropDowns = useMemo(() => {
        const dropDownList: Panel[] = [];

        const createDropDowns = (
            subNavItem: NavItem,
            persistingStackTrace: Panel['stackTrace'],
            depth?: number
        ) => {
            if (!subNavItem.children || subNavItem.children?.length === 0) {
                return;
            }

            const newDepth = typeof depth === 'number' ? (depth + 1) : 0;
            largestDepth.current = Math.max(largestDepth.current, newDepth)

            const newStackTrace = [...persistingStackTrace, subNavItem._key];

            const shouldRenderChildren = shouldChildLinksBeRendered(subNavItem, pathName || '');

            if (!shouldRenderChildren) {
                return;
            }

            const filteredChildren = (subNavItem.children || []).filter((navItem) => (
                shouldLinkBeRendered(navItem, pathName || '')
            ));

            dropDownList.push({
                title: subNavItem.title,
                parentKey: subNavItem._key,
                stackTrace: newStackTrace,
                depth: newDepth,
                navItems: filteredChildren,
            });

            subNavItem.children.forEach((child) => {
                createDropDowns(child, newStackTrace, newDepth);
            });
        }

        createDropDowns(navItem, []);

        return dropDownList;
    }, [navItem._key, pathName]);

    return {
        dropDowns,
        largestDepth: largestDepth.current
    };
};

export default useCreateDropDowns;
