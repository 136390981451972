import React from 'react';
import clx from 'classnames';

import { NavigationType } from '../../../../../../components/SiteHeader/SiteHeader';
import { useDropDownStackTraceContext, useDropDownSetStackTraceContext } from '../../context/DropDownContext';

import NavigationLink from '../../../../common/NavigationLink/NavigationLink';
import Icon from '../../../../../../components/_ui/_blocks/Icon/Icon';
import DiscountCode from '../../../../common/DiscountCode/DiscountCode';
import DiscountLink from '../../../../common/DiscountLink/DiscountLink';

import styles from './DropDownLink.module.css';

interface Props {
    navItem: NavigationType['items'][number];
    stack: string[];
}

const DropDownLink = ({ navItem, stack }: Props) => {
    const setStackTrace = useDropDownSetStackTraceContext();
    const stackTrace = useDropDownStackTraceContext();

    const isActive = stackTrace.includes(navItem._key);

    const linkClassNames = clx(
        styles.link,
        navItem?.demoted ? 'type-080' : '',
        isActive ? styles.linkActive : '',
    );

    const handleSetStack = () => {
        setStackTrace(stack);
    }

    if (navItem.type === 'discountCode') {
        return <DiscountCode navItem={navItem} />;
    }

    if (navItem.type === 'discountLink') {
        return <DiscountLink navItem={navItem} />;
    }

    return (
        <li className={styles.listItem}>
            {navItem.children?.length ? (
                <button
                    type="button"
                    onFocus={handleSetStack}
                    onMouseEnter={handleSetStack}
                    onMouseLeave={handleSetStack}
                    className={linkClassNames}
                    aria-haspopup
                    aria-expanded={isActive}
                    aria-controls={`mm-${navItem._key}`}
                >
                    {navItem.title}
                    <Icon
                        icon="chevron-right-light"
                        size="small"
                        alt=""
                        aria-hidden="true"
                        loading="eager"
                        className="ml-auto"
                    />
                </button>
            ) : (
                <NavigationLink
                    navItem={navItem}
                    className={linkClassNames}
                    activeClassName="font-600"
                >
                    {navItem.title}
                </NavigationLink>
            )}
        </li>
    );
};

export default DropDownLink;
