export const animationDuration = 6000; // Milliseconds

const bannerSelector = '[data-site-banner=true] li';

let timeout: ReturnType<typeof setTimeout> | undefined;
export const clearAnimationTimeout = () => {
    clearTimeout(timeout);
    timeout = undefined;
}

const getAnimationElements = (): HTMLElement[] => (
    Array.from(document.querySelectorAll(bannerSelector)) || []
);

const playAnimations = (element: HTMLElement) => {
    element.getAnimations().forEach((animation) => {
        animation.play();
    });

    const event = new Event('animationstart');
    element.dispatchEvent(event);
}

const handleNextAnimation = (e: Event) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        if (!e.target) {
            return;
        }

        const { nextElementSibling } = e.target as HTMLElement;

        if (nextElementSibling) {
            playAnimations(nextElementSibling as HTMLElement);
        } else {
            const firstElement = document.querySelector(bannerSelector) as HTMLElement;
            playAnimations(firstElement)
        }
    }, animationDuration - 480);
}

export const stopAnimations = () => {
    clearTimeout(timeout);

    const elements = document.querySelectorAll(bannerSelector)!;

    elements.forEach((element) => {
        element.getAnimations().forEach((animation) => {
            animation.finish();
        });

        element.removeEventListener('animationstart', handleNextAnimation);
    });
}

export const startAnimationSequence = () => {
    stopAnimations();

    const elements = getAnimationElements();
    if (!elements.length) {
        return;
    }

    elements.forEach((element) => {
        const animation = element.animate([
            { opacity: 0, transform: 'translateX(1.25rem)', offset: 0 },
            { opacity: 1, transform: 'translateX(0)', offset: 0.08 },
            { opacity: 1, transform: 'translateX(0)', offset: 0.92 },
            { opacity: 0, transform: 'translateX(-1.25rem)', offset: 1 }
        ], {
            duration: animationDuration,
            iterations: 1,
            fill: 'both', // Don't remove, needs fill otherwise calling finish() removes the animation.
        });

        animation.finish();

        element.addEventListener('animationstart', handleNextAnimation);
    });

    playAnimations(elements[0]);
}
