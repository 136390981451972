'use client';

import React from 'react';
import { useRouter } from 'next/navigation';

import { useSetAccountMenuOpenContext } from '../../../../data/context/SiteNavigationContext';
import { useAuthContext } from '../../../../data/context/authContext';

import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../../_ui/_blocks/Icon/Icon';

import styles from '../StickyHeader.module.css';

export const AccountButtonLoadingUi = () => (
    <Button
        variant="none"
        aria-label="Your Independent Pharmacy account"
        title="Your Independent Pharmacy account"
        className={styles.link}
    >
        <Icon icon="profile-dark" size="medium" alt="Profile" loading="eager" />
    </Button>
);

const AccountButton = () => {
    const router = useRouter();
    const { isLoggedIn, patientName } = useAuthContext();

    const setSidebarNavIsOpen = useSetAccountMenuOpenContext();
    const handleSidebarOpen = () => {
        if (isLoggedIn) {
            setSidebarNavIsOpen(true);
            return;
        }

        router.push('/login');
    }

    return (
        <Button
            variant="none"
            title={isLoggedIn ? 'Your Independent Pharmacy account' : 'Login to your Independent Pharmacy account'}
            className={styles.link}
            onClick={handleSidebarOpen}
        >
            <span className="hidden lg:inline">
                {isLoggedIn ? `Hello, ${patientName || 'user'}` : 'Sign In'}
            </span>
            <span className={isLoggedIn ? styles.signedInIcon : undefined}>
                <Icon icon="profile-dark" size="medium" alt="Profile" loading="eager" />
            </span>
        </Button>
    );
};

export default AccountButton;
