import React from 'react';
import type { PortableTextComponents} from 'next-sanity';
import {fontColors, getFontColorClass } from '../helpers/getFontColor';

/**
 * Portable Text components for highlighting text.
 * @param {string} [textColor] The tailwind color class to use for the highlight.
 * @returns {PortableTextComponents} The portable text components.
 */
export const textHighlightedComponents = (textColor?: keyof typeof fontColors) => ({
    block: {
        normal: ({ children }) => (
            children
        ),
    },
    marks: {
        highlight: ({ children }) => (
            <span
                className={textColor ? getFontColorClass(textColor) : 'text-promotion-sale'}
            >
                {children}
            </span>
        ),
    },
}) as PortableTextComponents;
